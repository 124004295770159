.close-button {
    position: absolute;
    top: 0px;
    right: 15px;
    background: none;
    border: none;
    font-size: 25px;
    cursor: pointer;
}

h3{

    margin-top: 40px;

}

.instructions{
    
    border-color: #36454F;
    background-color: #EEE2DE;
    border-width: 2px;
    margin-top: -90%;
    width: 350px;
    margin-left: auto;
    margin-right: auto;
  }

.scrollable{

    height: 350px;
    overflow: auto;
}

.image-with-text {
    display: flex; /* Use flexbox to align items horizontally */
    align-items: center; /* Align items vertically */
    gap: 10px; /* Adjust the gap between the image and text */
  }

.title {
    padding-top: 10px;
}

.body {
    line-height: 15px;
    font-size: 12px;
    margin-top: 14px;
    padding-left: 10px;
    padding-right: 10px;
 
    text-align: left;

    @apply text-gray-700 font-extralight;
}

.sub-title {
    line-height: 1.5vw;
    font-size: 17px;
    margin-top: 14px;
    @apply text-gray-700;
}

.sub-sub-title {
    line-height: 1.5vw;
    font-size: 13px;
    margin-top: 30px;
    margin-bottom: 0px;
    @apply text-gray-700;
}


.sub-body {
    line-height: 18px;
    font-size: 18px;
    margin-right: 200px;

    text-align: left;
    @apply text-gray-700;
}

.centered-images {
    display: flex; /* Use flexbox to align items horizontally */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Align items vertically */
  }
