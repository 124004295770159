
  .btn {
    font-size: 13px;
    @apply font-bold py-1 px-3 rounded mt-2;
  }
  .btn-blue {
    @apply bg-gray-600 text-white border-slate-300;
  }
  .btn-blue:hover {
    @apply bg-gray-600;
  }

  h6{
    font-size: 14px;
  }

  .dropdown-menu li:nth-child(even) {
    background-color: #dcdbdb; /* Set the background color for even rows */
  }
  
  .dropdown-menu li:nth-child(odd) {
    background-color: #ffffff; /* Set the background color for odd rows */
  }
  

  .dropdown-item{
    position: absolute;
    margin-top: 2px;
    z-index: 1;
  }
  


  @media (max-width: 500px) {

    .dropdown-item{



      }
      

  }
  
  


  

  

  

  

  
