@keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in-element {
    animation: fade-in 0.5s ease forwards;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    padding-top: 10px;
    left: 30%;
    right: 30%;
    z-index: 9999; /* Higher z-index value to ensure it is on top of other elements */
  }
  
  .overlay-full {
    position: fixed;
      top: 0;
      left: 0;
      width: 100vw; /* Use viewport width to extend the overlay across the entire screen */
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control the darkness */
  }

  .start-year{
    padding-left: 5px;
    opacity: .20;
  }

  .end-year{
    opacity: .20;
  }

  .dropdown{
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
  }

  .dropdown:empty{
    border:none;
  }

  .dropdown-row{
    cursor: pointer;
    text-align: start;
    margin: 5px 14px;
  }

  .right{
    margin-left: auto;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 10px;
  }

  @media (max-width: 500px) {
    .overlay {
      left: 3%;
      right: 3%;
    }
      
}