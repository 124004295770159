@tailwind base;
@tailwind components;
@tailwind utilities;

.App {}

.container {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px;
}

.far-right{

  margin-left: auto;
  margin-right: 5px;
}



h1 {
  padding-top: 10px;
  padding-left: 10px;
  @apply font-extrabold tracking-tight text-2xl text-gray-700;
}

h3 {
  line-height: 1.5vw;
  font-size: 10px;
  margin-top: 14px;
  text-align: center;
  opacity: 0.8;
  @apply font-extrabold text-gray-700;
}

.bold{
  font-size: 18px;
  @apply font-extrabold tracking-tight;

}

.not-bold{
  margin-top: -10px;
  margin-bottom: 30px;

  font-size: 15px;
  @apply font-bold tracking-tight text-gray-700;
}


.percentile{
  margin-top: -10px;
  margin-bottom: 30px;
}

.twitter {
  max-width: 25px;
  margin-top: 6px;
  margin-left: 10px;

  /* Set your desired maximum width */
  /* Maintain aspect ratio */
  filter: brightness(0.5);
}

.twitter:hover {
  opacity: 0.4;
}

.complete {
  max-width: 45%;
  height: auto;
  position: fixed;
  top: 30%;
  left: 11%;
  right: 10%;
  z-index: 9999;
}

.overlay-full {
  position: fixed;
    top: 0;
    left: 0;
    width: 100vw; /* Use viewport width to extend the overlay across the entire screen */
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control the darkness */
}

@media (max-width: 500px) {
  .complete {
    max-width: 90%;
    left: 5%;
    right: 5%;
  }
  
    
}
.ad-banner{
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
  position: fixed; /* or 'position: absolute;' depending on your needs */
  bottom: 0;
  width: 100%;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-element {
  animation: fade-in 0.75s ease forwards;
}



.center{
  display: flex;
  justify-content: center;
  align-items: center;

}