body {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  /* background-color: #EACAFF; */
  background-color: #fde88b;
  /* background-color: #EEE2DE; */
}

.centered {
  position: relative;
  top: -19px;
  color: #fff;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
  font-size: 13px;
  white-space: nowrap;
  /* Prevent line breaks */
  overflow: hidden;
  /* Hide overflowing text */
  text-overflow: clip;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 6px;
}

.top {
  position: absolute;
  top: 0px;
  left: 0px;
  color: #fff;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.8);
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 6px 0 6px 0;

}

h5 {
  line-height: 2.0vw;
  font-size: 1.1vw;
  text-align: center;
  @apply font-bold tracking-tight text-gray-600;
}

h4 {

  font-size: 2.0vw;
  text-align: center;
  @apply font-bold tracking-tight text-gray-700;
}


.grid-container {
  padding-right: 10px;
  display: grid;
  grid-gap: 3px;
  grid-template-columns: repeat(4, 1fr);
}

.grid-item {
  position: relative;
  width: 10vw;
  height: 10vw;
  text-align: center;
  /* background-color: #FFF9A6; */
  background-color: #e79aff;
  border-radius: 8px;
  border: 1px solid #000;
}

.grid-item-outer {
  width: 10vw;
  height: 10vw;
  display: flex;
  /* Enable flexbox */
  justify-content: center;
  /* Center the content horizontally */
  align-items: flex-end;
  /* Align the content to the bottom */
}

.teammate {
  width: 8.5vw;
  height: 8vw;
  display: flex;
  /* Enable flexbox */
  justify-content: center;
  /* Center the content horizontally */
  align-items: flex-end;

  /* Align the content to the bottom */
}

.text-teammate {

  display: flex;
  margin-bottom: -5%;
  justify-content: center;
  font-size: 1.5vw;
  @apply font-bold tracking-tight text-gray-700;

}



.grid-item-first {
  width: 10vw;
  height: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Center the content horizontally */
 
  /* Align the content to the bottom */
}


img {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  justify-content: center;
  object-fit: cover;
  opacity: 1.0;
}


.grid-item:hover {
  opacity: 0.50;
}

p {
  line-height: 1.5vw;
  font-size: 1.5vw;
  margin-bottom: 14px;
  text-align: center;
  @apply font-extrabold tracking-tight text-gray-700;
}



@media (max-width: 500px) {
  .grid-item {
    width: 24vw;
    height: 24vw;
  }

  .grid-item-first {
    width: 24vw;
    height: 24vw;
  }
  
  .teammate {
    width: 19vw;
    height: 19vw;
    display: flex;

  }
  
  .text-teammate {
  
    display: flex;
    margin-bottom: -5%;
    font-size: 3.5vw;
    @apply font-bold tracking-tight text-gray-700;
  
  }
  
  

  .grid-item-outer {
    width: 24vw;
    height: 24vw;
  }

  .centered {
    top: -14px;
    font-size: 10px;
  }

  p {
    line-height: 4vw;
    font-size: 3.7vw;
  }

  h5 {
    
    font-size: 2.5vw;
  }
  
  h4 {
    font-size: 4vw;
  }
  

  .top {
    font-size: 8px;
  }

}